import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import * as actionCreators from '../../store/actions'
import { getRolesInfoData } from '../../../va-corejs-v3/specializations'
import CheckIcon from '../../assets/svg/check-black.svg'
import './styles.scss'
import screenTypesMap from '../../layout/_parts/screenTypesMap'
import PermissionCell from './permissionCell'

const classNames = require('classnames')

export class RolesInfo extends React.Component {
  render() {
    const { device } = this.props
    const defaultLang = 'en'
    const data = getRolesInfoData()
    const { roles, permissions } = data
    const rolesSlugs = _.keys(roles)

    return (
      <div
        className={classNames({
          roles_info_wrapper: true,
          isSafari: device.isSafari,
          isFirefox: device.isFirefox,
          desk: device.screenType === screenTypesMap.desk,
          ipadh: device.screenType === screenTypesMap.iPadH,
          ipadv: device.screenType === screenTypesMap.iPadV,
          iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          iphonese: device.screenType === screenTypesMap.iPhoneSE,
        })}
      >
        <div className="roles_info_page_header">
          <div className="roles_info_back">
            <button
              type="button"
              onClick={() => {
                window.history.back()
              }}
            >
              <ChevronLeftIcon />
              Back
            </button>
          </div>
          <h1 className="roles_info_title">User rights according to role</h1>
        </div>

        <div className="roles_info_table_wrapper">
          <table className="roles_info_table">
            <thead>
              <tr>
                <th />
                {_.values(roles).map(role => (
                  <th className="header_roles_cell" key={_.uniqueId()}>
                    {role.label[defaultLang]}
                  </th>
                ))}
              </tr>
              <tr className="header_second_row">
                <th className="header_column_cell header_column_cell--upper">Rights</th>
                {_.values(roles).map(() => (
                  <th key={_.uniqueId()} />
                ))}
              </tr>
            </thead>
            <tbody>
              {permissions.map(permission => (
                <tr className="body_row" key={_.uniqueId()}>
                  <td className="header_column_cell header_column_cell--lower">
                    <PermissionCell permission={permission} />
                  </td>
                  {rolesSlugs.map(slug => (
                    <td className="check_cell" key={_.uniqueId()}>
                      {_.includes(permission.roles, slug) ? <CheckIcon /> : <></>}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(RolesInfo)
