import React from 'react'
import { connect } from 'react-redux'
import { Popper } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import _ from 'lodash'
import * as actionCreators from '../../store/actions'
import './styles.scss'

export class PermissionCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
    }
  }

  render() {
    const { permission } = this.props
    const { anchorEl } = this.state
    const defaultLang = 'en'
    const uniqueId = _.uniqueId()

    const handlePopoverOpen = event => {
      this.setState({
        anchorEl: event.currentTarget,
      })
    }

    const handlePopoverClose = () => {
      this.setState({
        anchorEl: null,
      })
    }

    const tooltipOpen = Boolean(anchorEl)

    return (
      <div className="permission_cell_inner_wrapper">
        {permission.label[defaultLang]}
        {permission.tooltip && (
          <>
            <HelpOutlineIcon
              aria-describedby={`mouse-over-tooltip-${uniqueId}`}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              className="tooltip_icon"
            />
            <Popper
              id={`mouse-over-tooltip-${uniqueId}`}
              className="roles_info_permission_tooltip"
              open={tooltipOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              placement="right"
            >
              {permission.tooltip[defaultLang]}
            </Popper>
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(PermissionCell)
