import React from 'react'
import { connect } from 'react-redux'

import Layout from 'layout'
import * as actionCreators from '../store/actions'
import RolesInfo from '../components/roles_info'
import NotFoundPage from './404'

/*-----------------------------------------------------*/
// Render Report
/*-----------------------------------------------------*/

export class RolesInfoPage extends React.Component {
  render() {
    const rolesInfoPageEnabled = process.env.rolesInfoPageEnabled ? JSON.parse(process.env.rolesInfoPageEnabled) : false

    if (!rolesInfoPageEnabled) {
      return <NotFoundPage />
    }

    return (
      <Layout>
        <RolesInfo />
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(RolesInfoPage)
